import React from 'react';
import { styled } from "@linaria/react";

import { useTheme, ITheme } from "@src/theme";
import Text from '@components/Text';

interface IStyleProps {
    theme: ITheme
}

const Container = styled.div<IStyleProps>`
    display: flex;
`

const Item = styled.div<IStyleProps>`
    flex: 1;
    box-sizing: border-box;
    padding: 15.55px 0 15.55px 0;
    text-align: center;
    background-color: ${props => props.theme.colors.grey.bluish};
    cursor: pointer;
    
    p {
        font-weight: 600 !important;
    }
    &:active, &.is-active {
        background-color: ${props => props.theme.colors.blue.default};
        p {
            color: ${props => props.theme.colors.white}
        }
        path {
            stroke: white;
        }
    }
    &:nth-child(1){
        border-radius: 7px 0 0 7px;
    }
    &:last-child{
        border-radius: 0 7px 7px 0;
    }
    &:not(:last-child){
        margin-right: 2px;
    }
    * {
        cursor: pointer;
    }
`

export interface ISegmented {
    id: string,
    name: string,
    icon?: React.ReactNode
}

interface ISegmentedProps {
    tabs: ISegmented[],
    onClick: (value: ISegmented) => void;
    activeId?: string
}

const Segmented = React.memo((props: ISegmentedProps) => {
    const { tabs, onClick, activeId } = props;
    const theme: ITheme = useTheme();

    return <Container theme={theme}>
        {tabs.map((item) => {
            return <Item
                key={item.id}
                theme={theme}
                className={activeId === item.id ? 'is-active' : ''}
                onClick={() => onClick(item)}>
                {item.icon}
                <Text variant='bodyHeader' color={theme.colors.blue.default}>{item.name}</Text>
            </Item>
        })}
    </Container>
})

export default Segmented;
