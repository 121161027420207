import React from 'react';
import { styled } from "@linaria/react";
import { useLocation, NavLink } from "react-router-dom";

import Text from '@components/Text';
import { useTheme, ITheme } from '@src/theme';


interface IStyleProps {
    theme: ITheme
}

const Container = styled.div<IStyleProps>`
    display: flex;
    margin-bottom: 5px;

    a {
        text-decoration: none;
        display: inline-flex;
        p:hover{
            cursor: pointer;
        }
    }
    p {
        color: ${props => props.theme.colors.blue.dark}
    }
`;

const NavItem = styled(Text)`
    font-size: 18px !important;
    text-transform: capitalize;
    letter-spacing: -0.2px;
    margin-right: 10px;
`

const LastItem = styled(NavItem)`
    font-weight: 700 !important;
    margin-right: 0;
`

const Breadcrumbs = () => {
    const { pathname } = useLocation();
    const theme: ITheme = useTheme();
    let pathNames = [''],
        currentPage = '';
    const convertRenderName = (name: string) => {
        if (name.includes('create')) {
            name = 'Create A New User';
        } else if (name.includes('edit')) {
            name = "Edit User's Detail";
        }else if(name.includes('modify')){
            name = "Modify "+ currentPage;
        }
        return name;
    }

    if(pathname.includes('account-management/edit')|| pathname.includes('/modify/')){
        if(pathname.includes('blacklist')){
            currentPage = 'Blacklist'
        }else{
            currentPage = 'Whitelist'
        }
        pathNames = pathname.split('/').filter(Boolean).slice(0,-1)
    }
    else{
        pathNames = pathname.split('/').filter(Boolean)
    }
    
    return <Container theme={theme}>
        {pathNames.map((name, index) => {
            let routeTo = `/${pathNames.slice(0, index + 1).join("/")}`;
            const renderedName = name.replaceAll('-', ' ');
            return index === pathNames.length - 1
                ? <LastItem color={theme.colors.blue.dark} key={`${name}`}>{convertRenderName(renderedName)}</LastItem>
                : <NavLink to={routeTo} key={`${name}`} className={({ isActive }) =>
                    isActive ? 'is-active' : undefined
                }>
                    <NavItem color={theme.colors.blue.dark}>{renderedName}</NavItem>
                    <NavItem color={theme.colors.blue.dark}>{'>'}</NavItem>
                </NavLink>
        })}
    </Container>
}

export default Breadcrumbs;
