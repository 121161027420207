import React from 'react';
import { styled } from '@linaria/react';

import Text from '@components/Text';


import { ITheme, useTheme } from '@src/theme';

interface StyleProps {
    size: number,
    color: string,
}

const Container = styled.div<{ margin?: string, position: string }>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.position};
    margin: ${props => props.margin || ''};
`

const SpinnerContainer = styled.div<StyleProps>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    box-sizing: border-box;
    border-radius: 50%;
    background: conic-gradient(from
        90deg at 50% 50%, rgba(0, 175, 170, 0.0001)
        0deg, ${props => props.color} 359.96deg, rgba(0, 175, 170, 0.0001) 360deg);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - ${props => props.size / 4}px),#fff 0);
    mask:radial-gradient(farthest-side,#0000 calc(100% - ${props => props.size / 4}px),#fff 0);
    animation spin .8s infinite linear;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

type SpinnerColorType = 'white' | 'black' | 'blue' | 'grey' | string;
type SpinnerPositionType = 'start' | 'center' | 'end'
export interface ISpinnerProps {
    size?: number,
    color?: SpinnerColorType,
    className?: string;
    margin?: string;
    position?: SpinnerPositionType;
    name?: string
}

const Spinner = (props: ISpinnerProps) => {
    const theme: ITheme = useTheme();
    const {
        size = 32,
        color = 'blue',
        position = 'start',
        className,
        margin,
        name
    } = props;

    const getColor = () => {
        switch (color) {
            case 'white':
                return theme.colors.white;
            case 'black':
                return theme.colors.black;
            case 'blue':
                return theme.colors.blue.default;
            case 'grey':
                return theme.colors.grey.default;
            default:
                return color;
        }
    }

    return <Container margin={margin} position={position}>
        <SpinnerContainer size={size} color={getColor()} className={className} />
        {name && <Text margin='0 10px' fontSize={size / 2}>{name}</Text>}
    </Container>
}

Spinner.defaultProps = {
    size: 32,
    color: '#596A86'
}

export default Spinner;
