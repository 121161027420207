import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { IBlacklistZone, ICategory } from '@models/category.model';
import { getList } from '@api/config';

export interface ICategoryState {
    isLoadingList: boolean,
    blacklistZones: IBlacklistZone[],
    categoriesMap: Map<string, ICategory[]> | null
}

interface ISetCategoryListPayloadAction {
    list_id: string
    categories: ICategory[],
}

const initialState: ICategoryState = {
    isLoadingList: false,
    blacklistZones: [],
    categoriesMap: null
};

const categorySlice = createSlice({
    name: 'GLOBAL-Categories',
    initialState,
    reducers: {
        setLoadingList: (state, action: PayloadAction<boolean>) => {
            state.isLoadingList = action.payload;
        },
        setBlacklistZones: (state, action: PayloadAction<IBlacklistZone[]>) => {
            state.blacklistZones = action.payload;
        },
        setCategoryList: (state, action: PayloadAction<ISetCategoryListPayloadAction | null>) => {
            if (action) {
                let newValue = new Map();
                if (current(state).categoriesMap) {
                    newValue = new Map(current(state)?.categoriesMap?.entries());
                }
                newValue.set(action?.payload?.list_id, action?.payload?.categories);
                state.categoriesMap = newValue;
            } else {
                state.categoriesMap = null
            }
        },
    },
});

export const getBlackzoneList = () => async (dispatch: any) => {
    try {
        dispatch(setLoadingList(true))
        const resp = await getList();
        const list = get(resp, 'data', []) as IBlacklistZone[];
        list.sort((a, b) => {
            const aName = a.list_description ? a.list_description.toLocaleUpperCase() : "";
            const bName = b.list_description ? b.list_description.toLocaleUpperCase() : "";
            if (aName > bName) {
                return 1
            } else if (aName < bName) {
                return -1
            } else {
                return 0
            }
        });
        dispatch(setBlacklistZones(list))
        dispatch(setLoadingList(false))
    } catch (error) {
        dispatch(setLoadingList(false))
    }
}

export const { setLoadingList, setBlacklistZones, setCategoryList } = categorySlice.actions;
export default categorySlice.reducer;
