import { protocol } from './index';
import { getAPIUrl } from '@utils/helper';
import { IApiGetUserPayload, IUserPayload } from './models/user';

const URL_GET_USER = '/user/getusers';
export const getUser = (payload: IApiGetUserPayload, page = 1, size = 25, signal?: AbortSignal) => {
    const path = getAPIUrl(URL_GET_USER, payload),
        postedValue = {
            PageNumber: page,
            PageSize: size

        }
    return protocol.post(path, postedValue, { signal: signal })
}

const URL_GET_USER_BY_ACCOUNTID = '/user/getuserbyid'
export const getUserByAccountId = (payload: number) => {
    const path = getAPIUrl(URL_GET_USER_BY_ACCOUNTID, {
        accountid: payload
    });
    return protocol.get(path)
}

const URL_CREATE_USER = '/user/adduser'
export const addUser = ( payload: IUserPayload) => {
    const path = getAPIUrl(URL_CREATE_USER, {});
    return protocol.post(path, payload)
}

const URL_EDIT_USER = '/user/edituser'
export const editUser = (modifiedById: string, payload: IUserPayload) => {
    const path = getAPIUrl(URL_EDIT_USER, {})
    return protocol.post(path, payload)
}

const URL_UPLOAD_IMAGE = '/user/updatephoto'
export const uploadImage = (accountId: string, payload: any, modifiedById: string) => {
    const path = getAPIUrl(URL_UPLOAD_IMAGE, {
        accountid: accountId,
        modifiedbyid: modifiedById
    });
    return protocol.post(path, payload)
}

const URL_GET_AVATAR = '/user/getphoto'
export const getAvatar = (accountId: string) => {
    const path = getAPIUrl(URL_GET_AVATAR, {
        accountid: accountId,
    });
    return protocol.get(path)
}

const URL_GET_ACCOUNT_SUMMARY = 'user/getAccountSummary'
export const getAccountSummary = ()=>{
    const path = getAPIUrl(URL_GET_ACCOUNT_SUMMARY,{})
    return protocol.get(path)
}

const URL_DELETE_USER = '/user/deleteuser'
export const deleteUser = (accountId: string) => {
    const path = getAPIUrl(URL_DELETE_USER, {
        accountId: accountId
    })
    return protocol.post(path)
}

const URL_REACTIVATE_USER = '/user/reactivateUser'
export const reactivateUser = (accountId: string) => {
    const path = getAPIUrl(URL_REACTIVATE_USER, {
        accountId: accountId
    })
    return protocol.post(path)
}