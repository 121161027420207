import React, { useState } from 'react';
import { styled } from '@linaria/react';

import { ITheme, useTheme } from '@src/theme';

import Button from '@components/Button';
import Text from '@components/Text';
import Header from '@components/Header';
import Pagination from '@components/Pagination';
import Breadcrumbs from '@components/Breadcrumbs';
import Checkbox, { CheckboxGroup } from '@components/Checkbox';
import Radio, { RadioGroup } from '@components/Radio';
import Dropdown, { IDropdownOption } from '@components/Dropdown';
import Modal from '@components/Modal';
import Modal2FA from '@components/Modal/Modal2FA';
import TextInput from '@components/TextInput';
import Tabs from '@components/Tabs';
import Segmented from '@components/Segmented';

import { AddListIcon, SortIcon, ViewIcon } from '@assets/icons';
import DropdownChangeView from '@components/Dropdown/DropdownChangeView';

const SORT_OPTIONS = [
    { name: 'Name In Alphabetical Order', value: 'name' },
    { name: 'Agency/Department', value: 'department' },
    { name: 'S/N: Biggest To Smallest', value: 'biggest' },
    { name: 'S/N: Smallest To Biggest', value: 'smallest' },
]
const Container = styled.div`
    padding: 30px;
`
const SectionWrapper = styled.div`
    margin: 30px 0 80px;
`
const Line = styled.div`
    display: flex;
`
const SectionHeader = styled(Text)`
    padding: 0 20px 15px;
    position: relative;
    margin-bottom: 15px;

    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px dashed black;
        width: calc(100% - 100px);
    }
`
const Section = ({ title, children }: { title: string, children?: React.ReactNode }) => {
    const theme: ITheme = useTheme();
    return <SectionWrapper>
        <SectionHeader variant='bodyHeader'
            fontWeight={500}
            color={theme.colors.blue.dark}>
            {title}
        </SectionHeader>
        {children}
    </SectionWrapper>
}

const ComponentTesting = () => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [sort, setSort] = useState<IDropdownOption | null>(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [tabId, setTabId] = useState('tab1');
    const [segmentedId, setSegmentedId] = useState('item-1');

    return <Container>
        <Header title='Component Testing' />
        <Section title='Tabs'>
            <Tabs
                activeId={tabId}
                tabs={[
                    {
                        id: 'tab1',
                        name: 'Tab 1',
                        url: '/'
                    },
                    {
                        id: 'tab2',
                        name: 'Tab 2',
                        url: '/'
                    },
                    {
                        id: 'tab3',
                        name: 'Tab 3',
                        url: '/'
                    }
                ]}
                onClick={(value: any) => setTabId(value?.id)} />
        </Section>
        <Section title='Segmented'>
            <Segmented
                activeId={segmentedId}
                tabs={[
                    {
                        id: 'item-1',
                        name: 'Item 1',
                    },
                    {
                        id: 'item-2',
                        name: 'Item 2',
                    },
                ]}
                onClick={(value: any) => setSegmentedId(value?.id)} />
        </Section>
        <Section title='Button'>
            <Line>
                <Button label='Primary' onClick={() => console.log('clicked')} />
                <Button label='Primary' disabled margin='0px 8px' onClick={() => console.log('clicked')} />
                <Button variant='secondary' onClick={() => console.log('clicked')}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>Secondary</Text>
                        <ViewIcon />
                    </div>
                </Button>
                <Button label='Tertiary' variant='tertiary' margin='0px 8px' onClick={() => console.log('clicked')} />
                <Button variant='borderless' onClick={() => console.log('clicked')}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AddListIcon />
                        <Text>Borderless</Text>
                    </div>
                </Button>
                <Button label='Confirm' variant='confirm' margin='0px 8px' onClick={() => console.log('clicked')} />
                <Button label='Delete' variant='delete' onClick={() => console.log('clicked')} />
            </Line>
        </Section>
        <Section title='Text'>
            <Line>
                <Text variant='bodyHeader' margin='0 20px'>Body Header</Text>
                <Text margin='0 20px'>Body</Text>
                <Text variant='smallText' margin='0 20px'>Small Text</Text>
                <Text variant='extraSmallText' margin='0 20px'>Extra Small Text</Text>
                <Text variant='miniText' margin='0 20px'>Mini Text</Text>
                <Text variant='largeText' margin='0 20px'>Large Text</Text>
                <Text variant='subheadBold' margin='0 20px'>Subhead Bold Text</Text>
            </Line>
        </Section>
        <Section title='TextInput'>
            <TextInput onChange={(value: any) => console.log(value)} />
        </Section>
        <Section title='Pagination'>
            <Pagination totalCount={100} pageSize={10} currentPage={currentPage} onChangePage={setCurrentPage} />
        </Section>
        <Section title='Breadcrumbs'>
            <Breadcrumbs />
        </Section>
        <Section title='Checkbox'>
            <Line>
                <Checkbox name='Checkbox1' value='value1' margin='0 20px' onChange={(event: any) => console.log(event)} />
                <Checkbox name='Disabled' value='disabled' disabled margin='0 20px' onChange={(event: any) => console.log(event)} />
            </Line>
            <CheckboxGroup label='Checkbox group' margin='15px 0' onChange={(event: any) => console.log(event)}>
                <Checkbox name='Item 1' value='item1' />
                <Checkbox name='Item 2' value='item2' />
                <Checkbox name='Item 3' value='item3' />
                <Checkbox name='Item 4' value='item4' />
            </CheckboxGroup>
        </Section>
        <Section title='Radio'>
            <Line>
                <Radio name='Radio 1' value='radio1' margin='0 20px' onChange={(event: any) => console.log(event)} />
                <Radio name='Unchangable' value='unchangable' unchangable margin='0 20px' onChange={(event: any) => console.log(event)} />
                <Radio name='Disable' value='disabled' disabled margin='0 20px' onChange={(event: any) => console.log(event)} />
            </Line>
            <RadioGroup label='Radio group' margin='15px 0' onChange={(event: any) => console.log(event)}>
                <RadioGroup.Item name='Item' value='Item' />
                <RadioGroup.Item name='Item' value='Item1' />
                <RadioGroup.Item name='Item' value='Item2' disabled />
            </RadioGroup>
        </Section>
        <Section title='Dropdown'>
            <Line>
                <Dropdown content={SORT_OPTIONS} value={sort} margin="0 12px 0 0" onClickOption={setSort}>
                    <Button variant='secondary' padding='11px 15px' className={sort ? 'is-active' : ''}>
                        <Text>Sort by</Text>
                        <SortIcon />
                    </Button>
                </Dropdown>
                <DropdownChangeView value={pageSize} margin="0 12px 0 0" onChange={setPageSize} />
            </Line>
        </Section>
        <Section title='Modal'>
            <Button onClick={() => setVisibleModal(true)}>Open Modal</Button>
            <Modal visible={visibleModal} onCancel={() => setVisibleModal(false)}>
                <Modal2FA
                    visible
                    reason='Add'
                    title='Wish to continue to remove this user from BRS?'
                    subtitle='This process can’t be undone.\nTo proceed, please enter the 6-digit code that was sent to you via email.' />
            </Modal>
        </Section>
    </Container>
}

export default ComponentTesting;
