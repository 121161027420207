import React from 'react';
import { styled } from '@linaria/react';
import { ITheme, useTheme } from '@src/theme';

import Text from '@components/Text';

interface IStyleProps {
    theme?: ITheme,
    margin?: string,
}

const Container = styled.div<{ margin?: string }>`
    margin: ${props => props.margin || ''}px;
    display: flex;
    flex-direction: column;
`;

const RadioWrapper = styled.div<{ margin?: string }>`
    display: flex;
    align-items: center;
    margin: ${props => props.margin || ''};

    * {
        cursor: pointer;
    }
    p {
        margin-left: 7px;
        width: max-content;
    }
`;

const RadioGroupWrapper = styled.div<{ maxColumns?: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.maxColumns || 4}, auto);
`;

const Label = styled(Text)`
    margin-bottom: 6px;
`
const RadioInput = styled.input<IStyleProps & { unchangable: boolean }>`
    position: relative;
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0;

    &:after {
        display: ${props => props.unchangable ? 'block' : 'none'};
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 2px;
        border: 1px solid ${props => props.unchangable ? props.theme?.colors.blue.default : props.theme?.colors.grey.default};
        background-color: ${props => props.theme?.colors.blue.default};
        background-clip: content-box;
    }
    &:checked{
        appearance: ${props => props.disabled ? 'auto' : 'none'};
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath d='M 7.5, 7.5 m -2.75, 0 a 2.75,2.75 0 1,0 5.5,0 a 2.75,2.75 0 1,0 -5.5,0' fill='%23ffffff' stroke='%23ffffff'/%3E%3C/svg%3E");
        background-color: ${props => props.theme?.colors.blue.mid};
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
    }
`;

const RadioSquare = styled(RadioInput)`
    appearance: none;
    box-sizing: border-box;
    border: 1px solid #596A86;
    background: white;
    width: 2em;
    height: 2em;
    flex: 0 0 2em;
    &:after {
        display: none;
    }
    &:checked {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 10 10' fill='none'%3E%3Cpath d='M4.28314 5.97519L8.61618 1.38672L9.2832 2.09248L4.28314 7.38672L1.2832 4.21028L1.94975 3.50451L4.28314 5.97519Z' fill='%23596A86' stroke='%23596A86'/%3E%3C/svg%3E"); 
        background-repeat: no-repeat;
        background-position: center;
        border-radius: unset;
    }
`;

const StyleRadio = {
    round: RadioInput,
    square: RadioSquare
};

interface IRadioGroupContext<T = any> {
    value: T,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
}

export const RadioGroupContext = React.createContext<IRadioGroupContext>({} as IRadioGroupContext);

type RadioType = 'round' | 'square';
export interface IRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
    type?: RadioType,
    value?: string;
    name?: string;
    disabled?: boolean;
    margin?: string;
    unchangable?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = React.forwardRef<HTMLInputElement, IRadioProps>((props: IRadioProps, ref) => {
    const { type = 'round', value, disabled, name, unchangable = false, onChange, ...rest } = props;
    const theme: ITheme = useTheme();
    const context = React.useContext(RadioGroupContext);
    const isChecked = context.value === value;

    const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (context) {
            context.onChange && context.onChange(event);
        } else {
            onChange && onChange(event);
        }
    }

    const Render = StyleRadio[type];

    return <RadioWrapper margin={props.margin}>
        <Render
            ref={ref}
            type='radio'
            theme={theme}
            value={value}
            disabled={disabled || unchangable}
            unchangable={unchangable}
            checked={isChecked}
            onChange={onHandleChange}
            {...rest} />
        {name && <Text color={theme.colors.blue.dark} content={name} />}
    </RadioWrapper>
})

interface IRadioGroup {
    label?: string;
    value?: string;
    disabled?: boolean,
    maxColumns?: number,
    margin?: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children: any;
}

export const RadioGroup = (props: IRadioGroup) => {
    const { label, value: defaultValue, disabled, maxColumns = 4, onChange, children } = props;
    const [value, setValue] = React.useState(defaultValue)
    const theme: ITheme = useTheme();

    const onHandleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event?.target.value;
        setValue(target)
        onChange && onChange(event);
    },[onChange])
    const contextValue = React.useMemo<IRadioGroupContext>(()=>({value: value, disabled: disabled, onChange: onHandleChange}),[value, disabled, onHandleChange])
    return <RadioGroupContext.Provider value={contextValue}>
        <Container margin={props.margin}>
            {label && <Label color={theme.colors.blue.dark} fontWeight={500} content={label} />}
            <RadioGroupWrapper maxColumns={maxColumns}>
                {children}
            </RadioGroupWrapper>
        </Container>
    </RadioGroupContext.Provider>
}

RadioGroup.Item = Radio;

RadioGroup.defaultProps = {
    maxColumns: 4
}

export default Radio;
