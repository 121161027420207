import React, { useMemo } from 'react';
import { styled } from "@linaria/react";

import Text from '@components/Text';
import { ArrowThinLeftIcon, DoubleArrowLeftIcon } from '@assets/icons';

const Container = styled.div`
    display: flex;
    align-self: center;
    margin-top: auto;
    padding: 20px 0 10px;
`

const Item = styled.button`
    border: none;
    background-color: transparent;
    margin: 0 2px;

    * {
        cursor: pointer;
    }
    p {
        font-weight: 300 !important;
    }
    :not(&.disabled):hover, &.is-selected {
        p {
            font-weight: 400 !important;
            text-decoration: underline;
        }
    }
    &.is-rotate180 {
        transform: rotate(180deg);
    }
    &.disabled {
        svg > path {
            stroke: #D7E1EB;
        }
        * {
        cursor: default;
        }
    }
`

const SingleArrowBtn = ({ ...args }) => <Item {...args}><ArrowThinLeftIcon /></Item>;
const DoubleArrowBtn = ({ ...args }) => <Item {...args}><DoubleArrowLeftIcon /></Item>;

const ToFirstPage = ({ disabled, ...args }: { disabled?: boolean, onClick?: Function }) => <DoubleArrowBtn {...args} className={disabled ? 'disabled' : ''} />,
    ToPrevPage = ({ disabled, ...args }: { disabled?: boolean, onClick?: Function }) => <SingleArrowBtn {...args} className={disabled ? 'disabled' : ''} />,
    ToNextPage = ({ disabled, ...args }: { disabled?: boolean, onClick?: Function }) => <SingleArrowBtn {...args} className={`is-rotate180 ${disabled ? 'disabled' : ''}`} />,
    ToLastPage = ({ disabled, ...args }: { disabled?: boolean, onClick?: Function }) => <DoubleArrowBtn {...args} className={`is-rotate180 ${disabled ? 'disabled' : ''}`} />;

interface IPaginationProps {
    totalCount: number,
    pageSize: number,
    currentPage: number,
    totalPages?: number,
    onChangePage?: (page: number) => void,
    siblingCount?: number,
    className?: string
}

const SIBLING_COUNT = 1;
const DOTS = "...";

const Pagination = React.memo((props: IPaginationProps) => {
    const { totalCount,
        pageSize,
        totalPages = Math.ceil(totalCount / pageSize),
        currentPage,
        siblingCount = SIBLING_COUNT,
        onChangePage,
        className } = props;
    const paginationRange = usePaginationRange({ totalCount, pageSize, siblingCount, currentPage });

    const onGoPrevPage = (isFirst = false) => {
        if (currentPage === 1) {
            return;
        }
        onChangePage && onChangePage(isFirst ? 1 : currentPage - 1)
    }
    const onGoNextPage = (isLast = false) => {
        if (currentPage === totalPages) {
            return;
        }
        onChangePage && onChangePage(isLast ? totalPages : currentPage + 1)
    }

    return <Container className={className}>
        <ToFirstPage disabled={currentPage === 1} onClick={() => onGoPrevPage(true)} />
        <ToPrevPage disabled={currentPage === 1} onClick={() => onGoPrevPage()} />
        {paginationRange?.map((item, index) => {
            const isNumber = typeof item === 'number';
            return <Item
                key={`pagination-${item}`}
                className={`${!isNumber ? 'disabled' : ''}${item === currentPage ? 'is-selected' : ''}`}
                onClick={() => isNumber && onChangePage && onChangePage(item)}>
                <Text>{item}</Text>
            </Item>
        })}
        <ToNextPage disabled={currentPage === totalPages} onClick={() => onGoNextPage()} />
        <ToLastPage disabled={currentPage === totalPages} onClick={() => onGoNextPage(true)} />
    </Container>
})

export default Pagination;

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
}

const usePaginationRange = ({ totalCount, pageSize, siblingCount = 1, currentPage }: {
    totalCount: number,
    pageSize: number,
    siblingCount: number,
    currentPage: number
}) => useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;
    if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
    }
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 3 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 3 + 2 * siblingCount;
        const rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
        );
        return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
        const middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
}, [totalCount, pageSize, siblingCount, currentPage])

