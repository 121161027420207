import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAgencyDept } from '@models/user.model';

export interface IUserState {
    agencyDept: IAgencyDept[] | null,
    userAvatar: string
}

const initialState: IUserState = {
    agencyDept: null,
    userAvatar: ''
};

const userSlice = createSlice({
    name: 'GLOBAL-User',
    initialState,
    reducers: {
        setAgencyDept: (state, action: PayloadAction<IAgencyDept[]>) => {
            state.agencyDept = action.payload;
        },
        setUserAvatar: (state, action: PayloadAction<string>) => {
            state.userAvatar = action.payload;
        },
    },
});

export const { setAgencyDept, setUserAvatar } = userSlice.actions;
export default userSlice.reducer;
