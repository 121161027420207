class StorageService {
    public static setStorage = (key: string, value: any, expiresIn = 7, storeSession = false) => {
        if (!key || !value) {
            return;
        }

        const now = Date.now(),
            expiredTime = Math.abs(expiresIn) * 60 * 60 * 24 * 1000 + now,//miliseconds for day
            storage = storeSession ? sessionStorage : localStorage;
        try {
            storage.setItem(key, JSON.stringify(value))
            storage.setItem(key + '_expiresIn', expiredTime.toString())
        } catch (error) {
            console.warn(`[setStorage]: Can not set value of key ${key} to storage. Error: ${error}`)
        }
    }

    public static removeStorage = (key: string, storeSession = false) => {
        try {
            const storage = storeSession ? sessionStorage : localStorage;
            storage.removeItem(key);
            storage.removeItem(key + '_expiresIn');
        } catch (error) {
            console.warn(`[removeStorage]: Error removing key ${key} from localStorage: Error: ${error}`);
        }
    }

    public static getStorage = <T = any>(key: string, storeSession = false): T | null => {
        if (key) {
            try {
                const now = Date.now();
                const expiredTime = Number(localStorage.getItem(key + '_expiresIn'));
                if (expiredTime < now) {
                    this.removeStorage(key)
                } else {
                    const storage = storeSession ? sessionStorage : localStorage;
                    const value = storage.getItem(key)
                    return value ? JSON.parse(value) : null;
                }
            } catch (error) {
                console.warn(`[getStorage]: Can not get value of key ${key} form local storage. Error: ${error}`)
            }
        }
        return null;
    }
}

export default StorageService;
