import React from 'react';

import Text from '@components/Text';

import { ITheme, useTheme } from '@src/theme';
import { CancelIcon } from '@assets/icons';

import {
    MaskOverlay, ModalWrapper,
    Container, Header, EscBtn, Content, Footer
} from './styles';

interface IModalContext {
    visible: boolean,
}

export const ModalContext = React.createContext<IModalContext>({
    visible: false
})
export interface IModalProps {
    visible: boolean,
    title?: string,
    onCancel: () => void,
    useOverlay?: boolean,
    children?: React.ReactNode,
    footer?: React.ReactNode,
    textPadding?: string,
    typePopup?: 'normal' | 'error'
}

const Modal = ({
    visible,
    title = '',
    onCancel,
    useOverlay = true,
    children,
    footer,
    textPadding,
    typePopup = 'normal'
    }: IModalProps) => {
    const theme: ITheme = useTheme();

    const getWidth = () => {
        const sideBarWidth = document.getElementById('side-bar')?.offsetWidth || 0,
            windowWidth = window.innerWidth;
        return {
            width: windowWidth - sideBarWidth,
            offsetLeft: sideBarWidth
        };
    }
    const contextValue = React.useMemo<IModalContext>(()=>({visible: visible}),[visible])
    return <ModalContext.Provider value={contextValue}>
        <MaskOverlay
            className={visible ? 'is-visible' : ''}
            visible={useOverlay}
            theme={theme}
            width={getWidth().width}
            left={getWidth().offsetLeft}>
            <ModalWrapper className={visible ? 'is-visible' : ''} >
                <Container theme={theme} type={typePopup}>
                    <Header paddingText={textPadding}>
                        <Text variant='largeText'>{title}</Text>
                        <EscBtn onClick={onCancel}>
                            <CancelIcon />
                        </EscBtn>
                    </Header>
                    <Content>
                        {children}
                    </Content>
                    <Footer>
                        {footer}
                    </Footer>
                </Container>
            </ModalWrapper>
        </MaskOverlay>
    </ModalContext.Provider>
};

export default Modal;
