import { styled } from "@linaria/react";
import { ITheme } from '@src/theme';

interface StyleProps {
    theme: ITheme,
    height?: number,
    width?: number,
    padding?:string,
    type?: 'normal' | 'error'
}

export const MaskOverlay = styled.div<StyleProps & { left: number, visible: boolean }>`
    position: fixed;
    height: 100vh;
    width: ${props => props.width ? props.width + 'px' : '100vw'};
    inset: 0;
    left: ${props => props.left}px;
    z-index: 100;
    background-color: rgba(256,256,256,0.8);
    opacity: 0;
    visibility: hidden;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        background-color: ${props => props.visible ? 'rgba(256,256,256,0.8)' : 'transparent'};
    }
`
export const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.6);
    padding-bottom: 60px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s 0.1s;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%) scale(1);
    }

`
export const Container = styled.div<StyleProps>`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border: ${props => props.type === 'error' ? `5px solid ${props.theme.colors.red.default}` : ''};
    background: ${props =>props.type === 'normal' ? props.theme.colors.blue.popUp : `linear-gradient(0deg, rgba(208, 33, 40, 0.20) 0%, rgba(208, 33, 40, 0.20) 100%), #FFF`};
    padding: 24px;
    border-radius: 16px;
    min-width:650px

    svg> path{
        stroke: ${props => props.type === 'error'? props.theme.colors.red.default : props.theme.colors.blue.default}
    }
`
export const Header = styled.div<{paddingText?: string}>`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
        white-space: nowrap;
        padding: ${props=> props.paddingText ? props.paddingText : ''}
    }
`
export const EscBtn = styled.div`
    cursor: pointer;
`
export const Content = styled.div`
    padding: 0 60px 20px;
`

export const Footer = styled.div``
