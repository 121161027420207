import React from 'react';
import { styled } from "@linaria/react";

import { useTheme, ITheme } from "@src/theme";
import Text from '@components/Text';

interface IStyleProps {
    theme: ITheme,
    className?: string
}

const Container = styled.div<IStyleProps>`
    display: flex;
    border-bottom: 1px solid ${props => props.theme.colors.blue.default};
    margin-bottom: 20px;
`
const Item = styled.div<IStyleProps>`
    flex: 1;
    box-sizing: border-box;
    padding: 12px 0 15px 0;
    text-align: center;
    border-bottom: 1px solid transparent;

    &:hover {
        p {
            font-weight: 600;
        }
    }
    &:active, &.is-active {
        font-weight: 600;
        border-bottom: 1px solid ${props => props.theme.colors.blue.default};
    }
    * {
        cursor: pointer;
    }
`
export interface ITabItem {
    id: string,
    name: string,
    url: string,
    icon?: string
}

interface ITabsProps {
    tabs: ITabItem[],
    onClick?: (e: any) => void;
    activeId?: string,
    comparator?: 'id' | 'url'
}

const Tabs = React.memo((props: ITabsProps) => {
    const { tabs: navigations,
        onClick,
        activeId,
        comparator = 'url' } = props;
    const theme: ITheme = useTheme();

    return <Container theme={theme}>
        {navigations.map((item) => {
            return <Item
                key={`nav-${item.id}`}
                theme={theme}
                className={activeId === item[comparator] ? 'is-active' : ''}
                onClick={() => onClick && onClick(item)}>
                <Text variant='bodyHeader' color={theme.colors.blue.dark}>{item.name}</Text>
            </Item>
        })}
    </Container>
})

export default Tabs;
