import { styled } from "@linaria/react";

import { ITheme } from '@src/theme';

interface StyleProps {
    margin?: string,
    theme?: ITheme
}


export const Container = styled.div<StyleProps>`
    margin: ${props => props.margin || ''};
    position: relative;
`;

export const Header = styled.div``;
export const ContentContainer = styled.div<StyleProps>`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    margin-top: 12px;
    z-index: 100;
    left: 50%;
    border-radius: 7px;
    background-color: ${props => props.theme?.colors.blue.tinted};
    box-shadow: 3px 3px 5px -2px rgba(0,0,0,0.6);
    -webkit-box-shadow: 3px 3px 5px -2px rgba(0,0,0,0.6);
    -moz-box-shadow: 43px 3px 5px -2px rgba(0,0,0,0.6);
    transform: scaleY(.8);
    &.is-visible {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%)
    }
`;
