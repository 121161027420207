import React from 'react';
import { styled } from "@linaria/react";

import Text from '@components/Text';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Spinner from '@components/Spinner';

import { ITheme, useTheme } from '@src/theme';
import { ErrorCode, useOTPController, IOTPInfo } from '@services/otpController';
import { OTPVerifyPurpose } from '@pages/AccountManagement/utils';

const Container = styled.div<{ visible: boolean }>`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
const Title = styled(Text)`
    margin-bottom: 5px;
`;
const Subtitle = styled(Text)`
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: 48px;
`

interface IModal2FAProps {
    reason: OTPVerifyPurpose,
    visible: boolean,
    className?: string;
    title?: string | React.ReactNode;
    titleColor?: string;
    subtitle?: string | React.ReactNode;
    onChange?: Function,
    onSubmit?: Function | (() => void);
}

const Modal2FA = React.memo(({ reason, visible, className, title, titleColor, subtitle, onChange, onSubmit }: IModal2FAProps) => {
    const { isCalling, generate, verifyOtpCode } = useOTPController();

    const [errorCode, setErrorCode] = React.useState<string>('');
    const [value, setValue] = React.useState<string>('');
    const [otp, setOtp] = React.useState<IOTPInfo | null>(null);
    const [isExpiredOtp ,setIsExpiredOtp] = React.useState<boolean>(false)

    const refInput = React.useRef<HTMLInputElement>(null);
    const isMount = React.useRef(false);
    
    const resetState = React.useCallback(()=>{
        setErrorCode('');
        setValue('');
        if (refInput?.current) {
            refInput.current.value = '';
        }
    },[])


    React.useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        resetState()
        if (visible) {
            get2FACode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, resetState])

    
    const get2FACode = async () => {
        const { hasError, errorMessage, otp: currentOtp } = await generate(reason);
        if (hasError) {
            setErrorCode(errorMessage);
        } else {
            setErrorCode('');
            setOtp(currentOtp);
        }
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text: string = event.target.value;
        text = text.trim();
        setValue(text)
        onChange && onChange(text);
    }

    const onHandleSubmit = async () => {
        if (!value || value === "") {
            setErrorCode(ErrorCode.BlankCode);
        } else {
            const optRowId: any = otp?.row_id 
            const result = await verifyOtpCode(optRowId,value);
            if (result?.hasError) {
                setError(result, setErrorCode, setIsExpiredOtp);
            } else {
                setErrorCode('');
                onSubmit && onSubmit();    
            }
        }
    }

    const onHandleResendOtp = () => {
        get2FACode();
        setIsExpiredOtp(false)
    }

    const getErrorMessage = () => {
        switch (errorCode) {
            case ErrorCode.BlankCode:
                return 'Please enter 2FA code!';
            case ErrorCode.WrongCode:
                return 'Invalid code. Please try again!';
            case ErrorCode.InactivedCode:
                return 'This code is inactived';
            case ErrorCode.ExpiredCode:
                return 'This code is expired!';
            case ErrorCode.CanNotVerified:
                return 'There is an error while verifing.\nPlease close the modal and try again!'
            default:
                return errorCode;
        }
    }

    const renderButton=(isExpiredOtp: boolean, isCalling: boolean, onSubmitOtp : ()=> any, onResendOtp : ()=> any)=>{
        return isExpiredOtp ? <Button
            disabled={!visible}
            variant='confirm'
            margin='20px 0 0 0'
            height={41}
            textColor={theme.colors.white}
            onClick={() => onResendOtp()}>
            {isCalling
                ? <Spinner size={24} />
                : <Text variant='bodyHeader' fontWeight={700} color={theme.colors.white}>Resend OTP</Text>}
        </Button> : <Button
            disabled={!visible}
            variant='confirm'
            margin='20px 0 0 0'
            height={41}
            textColor={theme.colors.white}
            onClick={() => onSubmitOtp()}>
            {isCalling
                ? <Spinner size={24} />
                : <Text variant='bodyHeader' fontWeight={700} color={theme.colors.white}>Verify</Text>}
        </Button>
        
    }

    const theme: ITheme = useTheme();
    return <Container className={className} visible={visible}>
        <Title variant='largeText' color={titleColor}>{title}</Title>
        <Subtitle color={theme.colors.blue.dark}>{subtitle}</Subtitle>
        <TextInput
            ref={refInput}
            value={value}
            label='2FA'
            type={'text'}
            placeholder='_ _ _ _ _ _'
            maxLength={6}
            borderRadius={0}
            height={52}
            textAlign='center'
            onChange={handleTextChange} />
        {errorCode && <Text color={theme.colors.red.default} variant='smallText' fontWeight={500} margin='14px 0 0 0'>
            {getErrorMessage()}
        </Text>}
        {renderButton(isExpiredOtp, isCalling, onHandleSubmit, onHandleResendOtp)}
    </Container>
})

function setError(result: { result: string; hasError: boolean; }, setErrorCode: React.Dispatch<React.SetStateAction<string>>, setIsExpiredOtp: React.Dispatch<React.SetStateAction<boolean>>) {
    const error: string = result.result;
    const isInactiveOrExpiredCode: boolean =  error === ErrorCode.ExpiredCode || error === ErrorCode.InactivedCode
    if (isInactiveOrExpiredCode) {
        setErrorCode(result.result);
        setIsExpiredOtp(true);
    } else {
        setErrorCode(result.result);
    }
}

export default Modal2FA;