import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { styled } from '@linaria/react';

import { ITheme, useTheme } from '@src/theme';
import { UserRole } from '@src/static/permission';
import { NAVIGATIONS } from '@src/static/navigation';

import Text from '@components/Text';
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Scrollbar from '@components/Scrollbar';

import { useHtxDispatch, useHtxSelector } from '@redux/hooks';
import { getBase64Prefix, getFullname, getIAuthByToken, shortenName } from '@utils/helper';
import { ArrowRightIcon, ArrowLeftIcon } from '@assets/icons';
import { getAvatar } from '@api/user';
import { setUserAvatar } from '@redux/slices/userSlice';

const COLLAPSED_WIDTH = 75, EXPANDED_WIDTH = 260;

interface IStyleProps {
    theme: ITheme,
    className?: string,
    width?: number
}

const SidebarWrapper = styled.div`
    height: 100vh;
    max-height: 100vh;
    display: flex;
    z-index: 100;
`
const Container = styled.div<IStyleProps>`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: ${props => props.width ?? 260}px;
    background-color: ${props => props.theme.colors.blue.tinted};
    padding: 37px 0 30px ;
    transition: width 0.3s;

    p {
        letter-spacing: -0.2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const ScrollbarContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const UserSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 52px;

    .user-role {
        text-transform: capitalize;
    }
`;
const LogoSection = styled.div`
    margin-bottom: 11px;
    text-align: center;
`;
const SignoutSection = styled.div<IStyleProps>`
    box-sizing: content-box;
    margin: 0 26px;
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid ${props => props.theme.colors.blue.default} ;
`;
interface INavItemProps {
    theme: ITheme,
    isActived: boolean
}
const Navigations = styled.div`
`;
const NavItem = styled.div<INavItemProps>`
    display: flex;
    box-sizing: content-box;
    justify-content: space-between;
    padding: 10px 0 8px 19px;
    max-width: 260px;
    background-color: ${props => props.isActived ? props.theme.colors.grey.bluish : 'initial'};

    * {
        cursor: pointer;
    }
    .nav-content {
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .nav-selector {
        background-color: ${props => props.isActived ? props.theme.colors.blue.dark : 'initial'};
        width: ${props => props.isActived ? 4 : 0}px;
    }
    p {
        margin-left: 5px;
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    &:hover {
        background-color: ${props => props.theme.colors.grey.bluish};
        p {
            font-weight: 700;
        }
        .nav-selector {
            background-color: ${props => props.theme.colors.blue.dark};
        }
        -webkit-transition: background-color 0.3 ease;
        -ms-transition: background-color 0.3 ease;
        transition: background-color 0.3 ease;
    }
`;
const Selector = styled.div`
    height: 28px;
    width: 0px;
    border-radius: 20px 0 0 20px;
    transition: width 0.1s ease-in-out;
`;
const CollapsibleSection = styled.div`
    position: relative;
`;
const CollapsibleButton = styled.div<IStyleProps>`
    width: 20px;
    height: 35px;
    background-color: ${props => props.theme.colors.blue.tinted};
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 30%;
    transform: translate(0, 50px);
`;
const EmptyLineHeight = styled.div<{ height: number }>`
    height: ${props => props.height}px;
`;

const Sidebar = () => {
    const token = useHtxSelector(state => state.auth.auth);
    const avatar = useHtxSelector(state => state.user.userAvatar);
    const profile = getIAuthByToken(token);
    const accountId: any= profile?.account_id
    const theme: ITheme = useTheme();
    const dispatch = useHtxDispatch()
    const navigate = useNavigate(), { pathname } = useLocation();
    const [isCollapsed, setCollapsed] = React.useState(false);
    

    const getAvatarPhoto = React.useCallback(async( accountId : string)=>{
        const resp = await getAvatar(accountId)
        if(resp.status === 200){
            const data : string = resp.data
            dispatch(setUserAvatar(data))
        }
    },[dispatch])
    
    React.useEffect(()=>{
        getAvatarPhoto(accountId)
    },[accountId, getAvatarPhoto])

    if (!profile) {
        return null;
    }

    const fullName = getFullname(profile.first_name, profile.last_name);

    const onClickCollapsibleBtn = () => {
        setCollapsed(!isCollapsed)
    }

    const renderNavigations = () => {
        const splitedPaths = pathname.split('/').slice(1);
        return <Navigations>
            {NAVIGATIONS
                .filter(item => item.roles.indexOf(profile.account_type) !== -1)
                .map(item => {
                    let isActived = false;

                    if (splitedPaths?.length === 1) {
                        isActived = splitedPaths[0] === item.id
                    } else if (splitedPaths?.length > 0) {
                        isActived = splitedPaths[1] === item.id;
                    }

                    return <NavItem key={item.id} theme={theme} isActived={isActived} onClick={() => navigate(item.url)}>
                        <div className='nav-content'>
                            <img src={item.icon} alt={item.id} />
                            {!isCollapsed && <Text variant='bodyHeader' fontWeight={500} color={theme.colors.blue.dark}>{item.name}</Text>}
                        </div>
                        <Selector className='nav-selector' />
                    </NavItem>
                })}
        </Navigations>
    }

    return <SidebarWrapper>
        <Container
            id='side-bar'
            theme={theme}
            width={isCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}>
            <Scrollbar>
                <ScrollbarContainer>
                    <LogoSection>
                        <img src={theme.logo.loginLogo} alt={'logo'} width='70px' height='70px'/>
                    </LogoSection>
                    <UserSection>
                        {!isCollapsed
                            ? <Text variant='body' color={theme.colors.blue.dark} margin='0 0 12px 0'>
                                Welcome back,
                            </Text>
                            : <EmptyLineHeight height={29} />}
                        <Avatar name={shortenName(fullName)} src={getBase64Prefix(avatar || '')} margin='0 0 6px 0' />
                        {!isCollapsed
                            ? <>
                                <Text color={theme.colors.blue.dark} fontWeight={500} margin='0 0 3px 0'>
                                    {fullName}
                                </Text>
                                <Text variant='extraSmallText' color={theme.colors.blue.dark} className='user-role'>
                                    {profile?.account_type}
                                </Text>
                            </>
                            : <EmptyLineHeight height={35} />}
                    </UserSection>
                    {renderNavigations()}
                    <SignoutSection theme={theme}>
                        {!isCollapsed
                            ? <Button variant='secondary' margin='0 auto' onClick={() => navigate('/logout')}>
                                <Text fontWeight={500} color={theme.colors.blue.dark}>Sign Out</Text>
                            </Button>
                            : <EmptyLineHeight height={43} />}
                    </SignoutSection>
                </ScrollbarContainer>
            </Scrollbar>
        </Container>
        <CollapsibleSection>
            <CollapsibleButton theme={theme} onClick={onClickCollapsibleBtn}>
                {isCollapsed
                    ? <ArrowRightIcon />
                    : <ArrowLeftIcon />
                }
            </CollapsibleButton>
        </CollapsibleSection>
    </SidebarWrapper>
}

Sidebar.defaultProps = {
    profile: {
        userName: 'User',
        role: UserRole.Admin
    }
}

export default Sidebar;
