import React from 'react';
import { styled } from "@linaria/react";
import classnames from 'classnames';

import Text from '@components/Text';
import { ITheme, useTheme } from '@src/theme';

interface StyleProps {
    margin?: string,
    theme?: ITheme
}

const Container = styled.div<StyleProps>`
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    background-color: ${props => props.theme?.colors.blue.tinted};
    min-width: 140px;
    min-height: 52px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    :not(:last-child) {
        ::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 10px;
            width: calc(100% - 20px);
            border-bottom: 1px dashed ${props => props.theme?.colors.blue.default};
        }
    }
    :first-child {
        border-radius: 7px 7px 0 0;
    }
    :last-child {
        border-radius: 0 0 7px 7px;
    }
    * {
        cursor: pointer;
    }
    :hover{
        background-color: ${props => props.theme?.colors.blue.popUp};
        * {
            background-color: ${props => props.theme?.colors.blue.popUp}
        };
    }
    &.is-selected {
        background-color: ${props => props.theme?.colors.blue.default};
        * {
            background-color: ${props => props.theme?.colors.blue.default}
        };
        p {
            color: ${props => props.theme?.colors.white || 'initial'};
        }
    }

    &.is-disabled {
        opacity: 0.6;
        cursor: default;
        user-select: none;
        * {
            cursor: default;
            user-select: none;
        }
    }
`;

const Arrow = styled.div<StyleProps>`
    position: absolute;
    background-color: ${props => props.theme?.colors.blue.tinted};
    height: 11px;
    width: 11px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: -5px;
`;

export interface IOptionProps {
    name: string,
    value?: string,
    selected?: boolean,
    disabled?: boolean,
    hasArrow?: boolean,
    onClick?: Function,
}

const Option = React.memo(React.forwardRef<HTMLDivElement, IOptionProps>((props: IOptionProps, ref) => {
    const { value, name, selected, disabled, hasArrow, onClick: onClickProp } = props;
    const theme: ITheme = useTheme();

    const onClick = () => {
        !disabled && onClickProp && onClickProp(value)
    }

    const Cls = classnames({
        'is-selected': selected,
        'is-disabled': disabled
    })

    return <Container
        ref={ref}
        theme={theme}
        className={Cls}
        onClick={onClick}>
        {hasArrow && <Arrow theme={theme} />}
        <Text variant='smallText' color={theme.colors.blue.dark} fontWeight={500}>
            {name}
        </Text>
    </Container>;
}));

export default Option;
