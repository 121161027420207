import React from 'react';

import { ITheme, useTheme } from '@src/theme';

import Text from '@components/Text';

import { CheckboxGroupWrapper, Container } from './styles';

interface ICheckboxGroupContext<T = any> {
    value: T,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: T) => void;
    disabled?: boolean
}

export const CheckboxGroupContext = React.createContext<ICheckboxGroupContext>({} as ICheckboxGroupContext);

interface ICheckboxGroup {
    label?: string;
    value?: any;
    disabled?: boolean,
    maxColumns?: number,
    margin?: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void;
    children: any;
}

const CheckboxGroup = (props: ICheckboxGroup) => {
    const { label, value: defaultValue, disabled, maxColumns = 4, onChange, children } = props;
    const [selectingValue, setSelectingValue] = React.useState(defaultValue)
    const theme: ITheme = useTheme();

    const onHandleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, value: any) => {
        const targetValue = event?.target.value;
        setSelectingValue(targetValue)
        onChange && onChange(event, value);
    },[onChange])
    
    const contextValue =React.useMemo<ICheckboxGroupContext>(()=>(
        {
            value: selectingValue, 
            disabled: disabled,
             onChange: onHandleChange
        }),[selectingValue, disabled, onHandleChange])
    
    return <CheckboxGroupContext.Provider value={contextValue}>
        <Container margin={props.margin}>
            {label && <Text color={theme.colors.blue.dark} fontWeight={500} margin='0 0 6px 0' content={label} />}
            <CheckboxGroupWrapper maxColumns={maxColumns}>
                {children}
            </CheckboxGroupWrapper>
        </Container>
    </CheckboxGroupContext.Provider>
}

CheckboxGroup.defaultProps = {
    maxColumns: 4
}

export { CheckboxGroup };