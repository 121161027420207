import React from 'react';
import { styled } from '@linaria/react';

import Sidebar from '@components/Sidebar';

const Container = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden;
`;

const Page = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 32px 63px 50px 35px;
`;

const Layout = (props: any) => {
    return <Container>
        <Sidebar />
        <Page>
            {props.children}
        </Page>
    </Container>
}

export default Layout;
