import React, { memo } from 'react';
import { Scrollbars as CustomScrollbars, ScrollbarProps } from 'react-custom-scrollbars';

export type { ScrollbarProps };

const Scrollbars = (props: ScrollbarProps) => {
    const { autoHide = true,
        autoHideTimeout = 600,
        autoHideDuration = 300,
        ...rest } = props;
    return <CustomScrollbars
        autoHide={autoHide}
        autoHideTimeout={autoHideTimeout}
        autoHideDuration={autoHideDuration}
        {...rest} />;
}

export default memo(Scrollbars);
