import { protocol } from './index';
import { getAPIUrl } from '@utils/helper';

const URL_GET_COMPONENT = '/config/getcomponent'
export const getComponent = (name: string, type?: string, value?: string) => {
    const path = getAPIUrl(URL_GET_COMPONENT, { name: name, type: type, value: value });
    return protocol.get(path);
}

const URL_GET_LIST = '/list/getlists'
export const getList = () => {
    return protocol.get(URL_GET_LIST);
}

const URL_GET_LIST_BY_LISTID = '/list/listbylistid'
export const getListByListId = (listId: string) => {
    const path = getAPIUrl(URL_GET_LIST_BY_LISTID, {
        listID: listId
    })
    return protocol.get(path)
}

const URL_GET_CATEGORY_LIST = '/list/categorylist'
export const getCategoryList = (listId: string, categoryId?: string) => {
    const path = getAPIUrl(URL_GET_CATEGORY_LIST, {
        categoryID: categoryId,
        listID: listId
    })
    return protocol.get(path)
}

const URL_EDIT_ISP_INTERVAL = '/config/editispinterval'
export const editISPInterval = (value: number) => {
    const path = getAPIUrl(URL_EDIT_ISP_INTERVAL, {
        value: value.toString(),
    });
     
    return protocol.post(path)
}

const URL_EDIT_SATIS_TRANSMISSION = '/config/editsatisautotransfer'
export const editSatisTransmission = (value: boolean) => {
    const path = getAPIUrl(URL_EDIT_SATIS_TRANSMISSION, {
        value: value.toString(),
    });
     
    return protocol.post(path)
}
