import DashboardIcon from '@assets/icons/ds-htx-dashboard-icon.svg';
import BlacklistIcon from '@assets/icons/ds-htx-blacklist-icon.svg';
import WhitelistIcon from '@assets/icons/ds-htx-whitelist-icon.svg';
import HistoryLogIcon from '@assets/icons/ds-htx-historylog-icon.svg';
import UserAdministrationIcon from '@assets/icons/ds-htx-user-administration-icon.svg';
import AccountManagementIcon from '@assets/icons/ds-htx-account-management-icon.svg';
import { UserRole } from './permission';

interface INavigationItems {
    id: string,
    name: string,
    url: string,
    icon?: string,
    roles: string[]
}

//need to check the user role for each navigation's item again in User Story
export const NAVIGATIONS = [
    {
        id: 'dashboard',
        name: 'Dashboard',
        url: '/dashboard',
        icon: DashboardIcon,
        roles: [UserRole.Admin, UserRole.Supervisor, UserRole.Operator]
    },
    {
        id: 'blacklist-administration',
        name: 'Blacklist Administration',
        url: '/dashboard/blacklist-administration',
        icon: BlacklistIcon,
        roles: [UserRole.Supervisor, UserRole.Operator]
    },
    {
        id: 'whitelist-administration',
        name: 'Whitelist Administration',
        url: '/dashboard/whitelist-administration',
        icon: WhitelistIcon,
        roles: [UserRole.Supervisor, UserRole.Operator]
    },
    {
        id: 'account-management',
        name: 'Account Management',
        url: '/dashboard/account-management',
        icon: AccountManagementIcon,
        roles: [UserRole.Admin]
    },
    {
        id: 'history-log',
        name: 'History Log',
        url: '/dashboard/history-log',
        icon: HistoryLogIcon,
        roles: [UserRole.Admin, UserRole.Supervisor, UserRole.Operator]
    },
    {
        id: 'user-administration',
        name: 'User Administration',
        url: '/dashboard/user-administration',
        icon: UserAdministrationIcon,
        roles: [UserRole.Supervisor]
    },
    {
        id: 'satis-third-party',
        name: 'SATIS Management',
        url: '/dashboard/satis-management',
        icon: AccountManagementIcon,
        roles: [UserRole.Admin]
    },
] as INavigationItems[];
