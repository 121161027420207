import React from 'react';

import Text from '@components/Text';

import { ITheme, useTheme } from '@src/theme';

import { Container, ErrorLine, InputWrapper, Label } from './styles';

export type TextAlign = 'left' | 'center' | 'right'

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    showErrorWarning?: boolean,
    errorText?: string,
    label?: string,

    //styles
    padding?: string,
    height?: number,
    width?: number,
    margin?: string,
    backgroundColor?: string,
    borderRadius?: number,
    borderColor?: string,
    suffixIcon?: React.ReactNode,
    prefixIcon?: React.ReactNode,
    textAlign?: TextAlign,
    className?: string
}

const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps & React.InputHTMLAttributes<HTMLInputElement>>((props: ITextInputProps, ref) => {
    const theme: ITheme = useTheme();
    const {
        label,
        padding,
        margin = '',
        height,
        width,
        borderRadius = 32,
        backgroundColor,
        borderColor = theme.colors.grey.default,
        suffixIcon = null,
        prefixIcon = null,
        textAlign = 'left',
        className,
        showErrorWarning = false,
        errorText = '',
        ...rest
    } = props;

    return <Container theme={theme} margin={margin} className={className}>
        {label && <Label color={theme.colors.blue.dark} fontWeight={500}>{label}</Label>}
        <InputWrapper
            theme={theme}
            padding={padding}
            margin={margin}
            height={height}
            width={width}
            textAlign={textAlign}
            borderColor={borderColor}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}>
            {prefixIcon}
            <input
                ref={ref}
                {...rest}
            />
            {suffixIcon}
        </InputWrapper>
        {showErrorWarning
            ? <ErrorLine>
                {errorText && <Text variant='extraSmallText' color={theme.colors.red.default}>{errorText}</Text>}
            </ErrorLine>
            : null}
    </Container>
})

TextInput.defaultProps = {
    placeholder: 'Placeholder',
    type: 'text',
    maxLength: 100,
    textAlign: 'left',
    padding: '11px 14px',
    borderRadius: 32,
    borderColor: '#EEF1F4',
}

export default TextInput;
