import React from 'react';
import { styled } from '@linaria/react';

import { ITheme, useTheme } from '@src/theme';

type Variant = 'bodyHeader' | 'body' | 'smallText' | 'extraSmallText' | 'miniText' | 'largeText' | 'subheadBold'
type TextAlign = 'center' | 'left' | 'right'| 'justify';
type TextTransform = 'uppercase' | 'lowercase' | 'capitalize';

interface StyledProps {
    theme: ITheme;
    color?: string;
    textAlign?: TextAlign;
    fontWeight?: string | number;
    fontSize?: number;
    lineHeight?: number;
    letterSpacing?: number;
    textTransform?: TextTransform;
    margin?: string | number;
}

const BaseText = styled.p<StyledProps>`
    cursor: default;
    font-family: ${props => props.theme.fontFamily['primary']};
    font-style: normal;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing + 'em' : '-0.2%'};
    margin: ${props => props.margin ?? ''};
    text-transform: ${props => props.textTransform || 'none'};
    text-align: ${props => props.textAlign || ''};
`

const BodyHeader = styled(BaseText)`
    font-size: ${props => props.fontSize ?? props.theme.fontSize['bodyHeader']}px;
    font-weight: ${props => (props.fontWeight ?? 'regular')};
    line-height: ${props => props.lineHeight || 19}px;
    color: ${props => props.color || props.theme.colors.blue.default};
`;

const Body = styled(BaseText)`
    font-size: ${props => (props.fontSize ?? props.theme.fontSize.body)}px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'regular')};
    line-height: ${props => props.lineHeight || 17}px;
    color: ${props => props.color || props.theme.colors.blue.default};
`;

const SmallText = styled(BaseText)`
    font-size: ${(props) => props.fontSize ?? props.theme.fontSize.smallText}px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'regular')};
    line-height: ${props => props.lineHeight || 16}px;
    color: ${props => props.color || props.theme.colors.blue.default};
`
const ExtraSmallText = styled(BaseText)`
    font-size: ${(props) => props.fontSize ?? props.theme.fontSize.extraSmallText}px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'regular')};
    line-height: ${props => props.lineHeight || 15}px;
    color: ${props => props.color || props.theme.colors.blue.default};
`;

const MiniText = styled(BaseText)`
    font-size: ${(props) => props.fontSize ?? props.theme.fontSize.mini}px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'regular')};
    line-height: ${props => props.lineHeight || 12}px;
    color: ${props => props.color || props.theme.colors.blue.default};
`;
const LargeText = styled(BaseText)`
    font-size: ${(props) => props.fontSize ?? props.theme.fontSize.h5}px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
    line-height: ${props => props.lineHeight || 24}px;
    letter-spacing: -0.5px;
    color: ${props => props.color || props.theme.colors.blue.dark};
`;
const SubheadBold = styled(BaseText)`
    font-size: ${(props) => props.fontSize ?? props.theme.fontSize.h6}px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
    line-height: ${props => props.lineHeight || 22}px;
    letter-spacing: -0.2px;
    color: ${props => props.color || props.theme.colors.blue.dark};
`

const TextStyle = {
    bodyHeader: BodyHeader,
    body: Body,
    smallText: SmallText,
    extraSmallText: ExtraSmallText,
    miniText: MiniText,
    largeText: LargeText,
    subheadBold: SubheadBold
}

interface ITextProps extends React.ComponentPropsWithoutRef<"p"> {
    variant: Variant,
    className?: string;
    color?: string,
    children?: React.ReactNode,
    content?: string,
    fontWeight?: string | number;
    fontSize?: number,
    lineHeight?: number,
    letterSpacing?: number;
    margin?: string;
    textAlign?: TextAlign,
    textTransform?: TextTransform;
}

const Text = (props: ITextProps) => {
    const { variant,
        children,
        className,
        color,
        fontWeight,
        fontSize,
        lineHeight,
        letterSpacing,
        textAlign,
        textTransform,
        margin,
        content,
        ...rest
    } = props;
    const theme: ITheme = useTheme();
    const Container = TextStyle[variant];

    return <Container
    theme={theme}
    className={className}
    color={color}
    textAlign={textAlign}
    fontWeight={fontWeight}
    fontSize={fontSize}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    textTransform={textTransform}
    margin={margin}
    {...rest}
    >
        {children || content}
    </Container>
}

Text.defaultProps = {
    variant: 'body',
}

export default Text;
