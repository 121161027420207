import React from 'react';
import { styled } from "@linaria/react";

import Text from '@components/Text';

import { PeopleIcon } from '@assets/icons';
import { useTheme, ITheme } from "@src/theme";

interface IAvatarStyle {
    size: number,
    theme: ITheme,
    margin?: string,
    backgroundColor?: string
}

const AvatarImage = styled.img<IAvatarStyle>`
    box-sizing: border-box;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border-radius: 50%;
    margin: ${props => props.margin || ''};
    border: 1px solid ${props => props.backgroundColor ?? props.theme.colors.blue.default};
    object-fit: cover;
`

const AvatarName = styled.div<IAvatarStyle>`
    box-sizing: border-box;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor ?? props.theme.colors.blue.default};
    margin: ${props => props.margin || ''};
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: ${props => props.theme.fontSize.h3}px;
    }
`

enum AvatarSize {
    sm = 40,
    md = 60,
    lg = 158
}

interface IAvatarProps {
    src?: string,
    size?: number | AvatarSize,
    name?: string,
    margin?: string,
    className?: string
}

const Avatar = (props: IAvatarProps) => {
    const { src, size = AvatarSize.md, name, ...rest } = props;
    const theme: ITheme = useTheme();

    if (!src) {
        return <AvatarName {...rest} size={size} theme={theme} >
            {name
                ? <Text color={theme.colors.white}>{name}</Text>
                : <PeopleIcon width={size / 2} height={size / 2} />}
        </AvatarName>
    }

    return <AvatarImage {...rest} src={src} size={size} theme={theme} alt={name} />
}

export default Avatar;
