import dayjs from "dayjs";

import { ID_LIST_USING_WILDCARD } from "@src/static/listData";
import { IToken, ITokenDescriptor } from '@models/jwt.model';
import { IAuth } from '@models/auth.model';
import jwtDecode from 'jwt-decode';
import { DomainAdministraionType } from "@pages/DomainAdmistration";
import { IGetDomainPayLoad } from "@models/domain.modes";
import { IModalFilter } from "@components/DataTableActions";
import { every, isDate, isEmpty, isNull, isObject } from "lodash";

export enum DATE_FORMAT{
    DEFAULT_FORMAT = 'dd/MM/yyyy',
    DATE_FORMAT = 'DD/MM/YYYY',
    DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm:ss',
    DATE_TWO_DIGIT_YEAR_FORMAT = "MMM'YY",
    DATE_MONTH_YEAR_FORMAT = "MM/YYYY"
}

export const getAPIUrl = (path: string, params: object) => {
    let paramPath = '';
    for (const [key, value] of Object.entries(params)) {
        if (value) {
            paramPath = paramPath + `&${key}=${encodeURIComponent(value)}`
        }
    }
    paramPath = paramPath.substring(1);
    return `${path}?${paramPath}`;
}

export const getFullname = (firstName?: string, lastName?: string) => {
    const firstname = firstName || '',
        lastname = lastName || '';
    return `${firstname} ${lastname}`;
}

export const shortenName = (text: string) => {
    if (!text) {
        return '';
    }

    const splitChar = text.split(' ');
    let result = '';
    const firstWord = splitChar[0],
        firstLetter = firstWord ? firstWord.substring(0, 1)?.toLocaleUpperCase() : '';

    if (splitChar.length > 1) {
        const lastWord = splitChar[splitChar.length - 1],
            lastLetter = lastWord ? lastWord.substring(0, 1)?.toLocaleUpperCase() : '';
        result = firstLetter + lastLetter;
    } else {
        result = firstLetter;
    }
    return result;
}

export const getBase64Prefix = (base64String: string) => {
    if (!base64String) {
        return '';
    }
    return `data:image/jpeg;base64,${base64String}`;
}

export const formatDate = (date?: string, type: DATE_FORMAT = DATE_FORMAT.DATE_TIME_FORMAT) => {
    if (!date) {
        return '';
    }
    return dayjs(date.replace('Z', '')).format(type).toString()
}

export class Validations {
    static MaxLengthSearchInput = 200
    static MaxLengthDomainInput = 253
    static MaxLengthCommonInput = 100
    static RegexAlphabet = /^[A-Za-z]+$/
    static RegexAlphabetBlankSpace = /^[A-Za-z\s]+$/
    static RegexAlphabelNumeric = /^[A-Za-z0-9]+$/
    static RegexNumeric = /^\d+$/
    static RegexSingaporePhoneNumber = /\+65([689])\d{7}/
    static RegexAlphanumericBlankSpace = /^[A-Za-z0-9\s]+$/
    static RegexEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    static RegexDomain = /(\*\.)*(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    static validateDomain = (domain: string, listId: string, typeDomain : DomainAdministraionType = 'blacklist') => {
        let result;
        if (!domain) {
            return 'Please enter domain.';
        }
        const match = domain.match(Validations.RegexDomain);
        if(typeDomain === 'blacklist'){
            if ((domain.startsWith('*.') && !ID_LIST_USING_WILDCARD.includes(listId))
                || (!domain.startsWith('*.') && ID_LIST_USING_WILDCARD.includes(listId))) {
                result = 'Wildcard domain must go with List 12 or List 83.'
            } 
        }
        else if (match?.length === 0) {
            result = 'Invalid domain.'
        } else {
            result = true;
        }
        return result;
    }

    static RegexCommonTextInput = /^[a-zA-Z0-9._-]+$/;
    static validateCommonTextInput = (value: string, name = 'value') => {
        let result;
        if (!value) {
            result = `Please enter ${name}.`;
        } else {
            if (value.length > 100) {
                result = 'Max input length is 100 characters.'
            } else if (!this.RegexCommonTextInput.test(value)) {
                result = `Invalid ${name}.`
            }
        }
        return result;
    }

    static validateWildCard =(domain: string)=>{
        const invalidWildCard = !domain.startsWith("*.")
        if (invalidWildCard) {
            domain = domain.replace(/^\*?\.?/, "*.")
        }
        return domain
    }
    
    static validateInvalidReason = (reason: string)=>{
        let result;
        const isInvalidStr: boolean = reason.toLowerCase().startsWith('[urgent]')
        if (!reason) {
            result = 'Please enter reason.';
        }
        if (isInvalidStr) {
            result = 'Reason cannot start with [Urgent]'
        }
        return result
    }

    static validatePhoneNumber = (phone: string)=>{
        let result;
        if(isEmpty(phone)){
            result = true;
        }else if (!isEmpty(phone) && !this.RegexSingaporePhoneNumber.test(phone)) {
            result = 'Contact number is invalid';        
        } else {
            result = true;
        }
        return result;
    }

    static validateWhitelistWildcard = (domain: string) => {
        if(domain.length === 2 && domain.startsWith('*') && !domain.startsWith('*.')){
            domain = domain.replace(/^\*?\.?/, "*.")
        }
        return domain;
    }
    
    static insertCountryCode = (phoneNumber: string) => {
        // Remove any non-digit characters from the phone number
        const digitsOnly = phoneNumber.replace(/\D/g, '');         
        // Check if the phone number starts with "65"
        if(isEmpty(digitsOnly)){
            return '';
        } else if(digitsOnly.length === 2 && !digitsOnly.startsWith('65')){
            return `+65${digitsOnly[1]}`
        }else if (digitsOnly.startsWith('6') || digitsOnly.startsWith('65')) {
            return `+${digitsOnly}`;
        }else {
            return `+65${digitsOnly}`;
        }
    }
}

export function getIAuthByToken(token: IToken | null) {
    try {
        if (token) {
            const tokenDesc = jwtDecode<ITokenDescriptor>(token.token);
            return JSON.parse(tokenDesc.user) as IAuth;
        }
        else
            return null;
    } catch (error) {
        return null;
    }
}

export function numberWithCommas(x?: number): string {
    if(x === undefined){
        return '';
    }
    return x.toLocaleString('en-US');
}

export function getPayloadFilter(pageNumber: number, pageSize: number, filterBy: IModalFilter, sortBy?: string): IGetDomainPayLoad{
    const validPayload = {
        filterBy: {                
            ...filterBy,
            date:{
                startDate:filterBy.date.startDate ? filterBy.date.startDate?.toLocaleDateString('en-CA'): "",
                endDate:filterBy.date.endDate ? filterBy.date.endDate?.toLocaleDateString('en-CA'): ""
            }   
        },
        sortBy: sortBy,
        pagingParam:{
            pageNumber: pageNumber,
            pageSize: pageSize
        }
    } as IGetDomainPayLoad
    return validPayload;
}

export function checkAllPropertiesEmptyOrNull(object : Object): boolean{
    return every(object, (key) => {
        let value = isDate(key) ? key.toDateString() : key
        if (isObject(value)) {
          return checkAllPropertiesEmptyOrNull(value);
        }
        return isEmpty(value) || isNull(value);
      });
}