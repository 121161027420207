import React from 'react';

import Dropdown, { IDropdownOption } from "./Dropdown"
import Button from '@components/Button';
import Text from '@components/Text';

import { VIEW_OPTIONS } from "@src/static/listData"
import { ViewIcon } from '@assets/icons';

interface IDropdownChangeViewProps {
    value: number,
    onChange: Function | ((v: number) => void),
    margin?: string
}

const DropdownChangeView = React.memo((
    {
        value: propValue,
        onChange,
        margin
    }: IDropdownChangeViewProps) => {
    const onClickItem = React.useCallback((value: IDropdownOption<number>) => {
        onChange && onChange(value.value)
    }, [onChange])

    return <Dropdown content={VIEW_OPTIONS} value={propValue} margin={margin} onClickOption={onClickItem}>
        <Button variant='secondary' padding='11px 15px'>
            <Text>View</Text>
            <ViewIcon />
        </Button>
    </Dropdown>
})

export default DropdownChangeView;
