import React from 'react';

import { ITheme, useTheme } from '@src/theme';

import Option from '@components/Option';

import { Container, ContentContainer, Header } from './styles';
export interface IDropdownOption<T = any> {
    name: string,
    value: T
}

interface IDropdownProps<T = any> {
    value?: T;
    content: IDropdownOption<T>[],
    trigger?: 'click' | 'hover'
    children: React.ReactNode,
    margin?: string,
    onClickOption?: (v: IDropdownOption) => void,
    onVisibleChange?: () => void
}

const Dropdown = React.memo((props: IDropdownProps) => {
    const { value: propValue, content, trigger = 'click', children, margin, onClickOption } = props;
    const theme: ITheme = useTheme();
    const [showContent, setShowContent] = React.useState(false);
    const refHeader = React.useRef<HTMLDivElement>(null),
        refList = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        document.addEventListener('click', onHandleClickOutside, true);
        return () => {
            document.removeEventListener('click', onHandleClickOutside, true);
        };
    }, []);

    const onHandleShowContent = (action: 'click' | 'hover') => {
        if (action === trigger) {
            setShowContent(!showContent);
        }
    }

    const onHandleClickOption = (value: IDropdownOption) => {
        onClickOption && onClickOption(value);
        setShowContent(false);
    }

    const onHandleClickOutside = (event: any) => {
        if (!refHeader?.current?.contains(event.target) && !refList?.current?.contains(event.target)) {
            setShowContent(false);
        }
    }

    return <Container margin={margin}>
        <Header
            ref={refHeader}
            onClick={() => onHandleShowContent('click')}
            onMouseEnter={() => onHandleShowContent('hover')}>
            {children}
        </Header>
        <ContentContainer className={showContent ? 'is-visible' : ''} ref={refList} theme={theme}>
            {content.map((item, index) => {
                return <Option
                    key={item.value}
                    name={item.name}
                    selected={propValue === item.value}
                    hasArrow={index === 0}
                    onClick={() => onHandleClickOption(item)} />
            })}
        </ContentContainer>
    </Container>
})

export default Dropdown;
