import { IToken } from '@models/jwt.model';
import { getAPIUrl } from '@utils/helper';
import { protocol } from './index';
import { IApiLoginPayload } from './models/authentication';

const URL_GENERATE_2FA = '/otp/getOtp';
export const generate2FA = (actionOtp: string) => {
    const path = getAPIUrl(URL_GENERATE_2FA, {
        actionOtp: actionOtp
    });
    return protocol.get(path);
}

const URL_VERIFY_2FA = '/otp/verifyOtp'
export const verifyOtp = (rowId: number, otpCode: string)=>{
    const path = getAPIUrl(URL_VERIFY_2FA, {
        rowId: rowId,
        otpCode: otpCode
    })
    return protocol.post(path)
}

const URL_ADLOGIN = '/identity/sign-in';
export const getAdLoginUrl = () => {
    return protocol.get(URL_ADLOGIN)
}

const URL_ADLOGOUT = '/identity/sign-out';
export const getAdLogoutUrl = (payload: IToken | null) => {
    return protocol.post(URL_ADLOGOUT, payload)
}

const URL_LOGIN = '/identity/by-password';
export const identityPassword = (payload: IApiLoginPayload) => {
    return protocol.post(URL_LOGIN, payload)
}

const URL_LOGIN_AZ = '/identity/by-azure';
export const identityAz = (token: string) => {
    return protocol.get(URL_LOGIN_AZ, { headers: {
        'Authorization': `Bearer ${token}`
    } })
}