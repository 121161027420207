import { styled } from "@linaria/react";

import Text from '@components/Text';

import { ITheme } from '@src/theme';

import { TextAlign } from './TextInput';

interface IStyleProps {
    theme: ITheme,
    height?: number;
    width?: number,
    padding?: string,
    margin?: string,
    backgroundColor?: string,
    borderRadius?: number,
    borderColor?: string,
    textAlign?: TextAlign
}

export const Container = styled.div<IStyleProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    margin: ${props => props.margin || ''};
`
export const Label = styled(Text)`
    margin-bottom: 6px;
`
export const InputWrapper = styled.div<IStyleProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.padding || '11px 14px'};
    height: ${props => props.height ? props.height + 'px' : 'max-content'};
    width: ${props => props.width ? props.width + 'px' : '100%'};
    background-color: ${props => props.backgroundColor || props.theme.colors.white};
    border: 1px solid ${props => props.borderColor || props.theme.colors.grey.default};
    border-radius: ${props => props.borderRadius ?? 32}px;
    box-sizing: border-box;
    
    input {
        flex: 1;
        padding: 0;
        border: 0;
        outline: none;
        font-size: 14px;
        text-align: ${props => props.textAlign || 'left'};
        background-color: ${props => props.backgroundColor || props.theme.colors.white};
        color: ${props => props.theme.colors.blue.dark}
    } 
    input::placeholder {
        color: ${props => props.theme.colors.blue.dark};
        opacity: 0.3; 
    }
    input:-ms-input-placeholder {
        color: ${props => props.theme.colors.blue.dark};
        opacity: 0.3; /* Firefox */
    }
    input::-ms-input-placeholder {
        color: ${props => props.theme.colors.blue.dark};
        opacity: 0.3; 
    }
    svg {
        flex: 0 0 16px;
        margin-left: 10px;
    }
`
export const ErrorLine = styled.div`
    height: 15px;
    margin: 5px 0 10px;
    align-self: baseline;
`