import { createTheming, ThemingType } from '@callstack/react-theme-provider';
import defaultLogo from '@src/assets/logos/ds-htx-spf-logo.png';
import loginLogo from '@src/assets/logos/ds-htx-spf-logo-login.webp'
interface IColor {
  primary: string;
  white: string;
  primaryWhite: string;
  black: string;
  grey: any;
  blue: any;
  green: any;
  cyan: any;
  red: any;
  yellow: any;
  tag: any;
}

interface IFontSize {
  display: number,
  h1: number,
  h2: number,
  h3: number,
  h4: number,
  h5: number,
  h6: number,
  bodyHeader: number,
  body: number,
  smallText: number,
  extraSmallText: number,
  mini: number
}

export interface ITheme {
  colors: IColor,
  fontFamily: any,
  fontSize: IFontSize,
  logo: {
    default: string,
    loginLogo: string
  }
}

export const themeDefault: ITheme = {
  colors: {
    primary: '',
    white: '#FFFFFF',
    primaryWhite: '#E9E9E9',
    black: '#000000',
    grey: {
      default: '#EEF1F4',
      bluish: '#E4ECF1',
      lineDark: '#7A7B7F'
    },
    blue: {
      default: '#596A86',
      dark: '#273B4A',
      mid: '#345E90',
      bright: '#489CEA',
      tinted: '#F4F7F8',
      clear: '#E7EDFB',
      popUp: '#D7E1EB'
    },
    red: {
      default: '#D02128',
      deep: '#9B0A10',
      dark: '#76090D'
    }
  } as IColor,
  fontFamily: {
    primary: 'Inter'
  },
  fontSize: {
    display: 64,
    h1: 44,
    h2: 34,
    h3: 28,
    h4: 24,
    h5: 20,
    h6: 18,
    bodyHeader: 16,
    body: 14,
    smallText: 13,
    extraSmallText: 12,
    mini: 10,
  },
  logo: {
    default: defaultLogo,
    loginLogo: loginLogo
  }
};

interface OverrideTheme<Theme = ITheme> extends ThemingType<Theme> {
  ThemeProvider: React.ComponentType<{ theme?: Theme; children?: any }>;
}

export const { ThemeProvider, withTheme, useTheme } = createTheming(themeDefault) as OverrideTheme;
