import { ITheme } from "@src/theme";
import { Variant } from "./Button";

export const getPadding = (variant: Variant) => {
    switch (variant) {
        case 'primary':
            return '11px'
        case 'secondary':
        case 'delete':
            return '13px 14px';
        case 'borderless':
            return '11px 19px';
        case 'confirm':
            return '11px 49px'
        default:
            return '11px'
    }
}

export const getTextColor = (disabled: boolean, variant: Variant, theme: ITheme) => {
    if (disabled) {
        return theme.colors.blue.default
    }
    switch (variant) {
        case 'primary':
        case 'tertiary':
        case 'borderless':
        case 'delete':
            return theme.colors.blue.default;
        case 'secondary':
            return theme.colors.blue.dark;
        case 'confirm':
            return theme.colors.white;
        default:
            return theme.colors.blue.default;
    }
}

export const getBorderRadius = (variant: Variant) => {
    switch (variant) {
        case 'confirm':
            return '6px';
        case 'delete':
            return '0px 0px 7px 7px';
        default:
            return '32px'
    }
}

export const getBorder = (disabled: boolean, variant: Variant, theme: ITheme) => {
    if (disabled) {
        return `1px solid ${theme.colors.grey.default}`
    }
    switch (variant) {
        case 'primary':
            return `1px solid ${theme.colors.blue.default}`;
        case 'secondary':
        case 'tertiary':
            return `1px solid ${theme.colors.grey.default}`;
        default:
            return `1px solid transparent`
    }
}

export const getBackground = (disable: boolean, variant: Variant, theme: ITheme) => {
    if (disable) {
        return 'transparent'
    }
    switch (variant) {
        case 'borderless':
        case 'delete':
            return theme.colors.grey.bluish;
        case 'confirm':
            return theme.colors.blue.default;
        case 'primary':
        case 'secondary':
        case 'tertiary':
        default:
            return theme.colors.white;

    }
}

export const getHoveredBackground = (disable: boolean, variant: Variant, theme: ITheme) => {
    if (disable) {
        return 'inherit'
    }
    switch (variant) {
        case 'tertiary':
            return theme.colors.white;
        case 'confirm':
            return theme.colors.blue.dark
        default:
            return theme.colors.blue.default;
    }
}

export const getTextMargin = (variant: Variant) => {
    switch (variant) {
        case 'secondary':
            return '0 5px 0 0';
        case 'borderless':
            return '0 0 0 12px';
        case 'primary':
        default:
            return '0px';

    }
}

export const getSize = (value: number | string | undefined) => {
    let result = 'max-content';
    if (typeof value === 'string') {
        result = value;
    } else if (typeof value === 'number') {
        result = `${value}px`;
    }
    return result;
}
