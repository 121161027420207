import React from "react";
import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';

import { useTheme, ITheme } from '@src/theme';
import Breadcrumbs from "@components/Breadcrumbs";
import Text from "@components/Text";
import Button from "@components/Button";
import { ArrowLeftIcon } from "@assets/icons";

const Container = styled.div`
    display: block;
    margin-bottom: 20px;    
`;

const TopArea = styled.div`
    padding: 15px 0;
`

const Line = styled.div<{ theme: ITheme }>`
    width: 100%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.grey.lineDark};
`

const BackButton = styled(Button)`
    margin-top: 26px;
    padding: 12px 20px;
    align-items: center;

    svg {
        margin-top: 2px;
        margin-right: 8px;
    }
`

const Title = styled(Text)`
    margin-top: 15px;
    font-size: 18px;
    letter-spacing: -0.2px;
`

interface IHeaderProps {
    title?: string,
    showTitle?: boolean,
    showBackButton?: boolean,
    showActions?: boolean,
}

const Header = React.memo(({ title = '', showBackButton = false, showActions = false, showTitle = true }: IHeaderProps) => {
    const navigate = useNavigate();
    const theme: ITheme = useTheme();
    return <Container>
        <TopArea>
            <Breadcrumbs />
        </TopArea>
        <Line theme={theme} />
        {showBackButton && <BackButton variant="tertiary" textColor={theme.colors.blue.dark} onClick={() => navigate(-1)}>
            <ArrowLeftIcon width={8} height={8} fill="#596A86" />
            <Text fontWeight={500}>Back</Text>
        </BackButton>}
        {showTitle && <Title color={theme.colors.blue.dark} fontSize={18}>{title}</Title>}
    </Container>
})

export default Header;
