import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORAGE_KEY_AUTHENTICATION } from '@static/authentication';
import StorageService from '@services/StorageService';
import { IToken } from '@models/jwt.model';

export interface IAuthState {
	auth: IToken | null;
}

const initialState: IAuthState = {
	auth: StorageService.getStorage<IToken>(STORAGE_KEY_AUTHENTICATION)
};

const authSlice = createSlice({
	name: 'GLOBAL-Authentication',
	initialState,
	reducers: {
		setAuthState: (state, action: PayloadAction<IToken | null>) => {
			state.auth = action.payload;
		},
	},
});

export const { setAuthState } = authSlice.actions;
export default authSlice.reducer;
