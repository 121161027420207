import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import ComponentTesting from "@pages/ComponentTesting";
import { useHtxSelector, useHtxDispatch } from '@redux/hooks';
import { getBlackzoneList } from "@redux/slices/categorySlice";

import { IRoute, ROUTES } from "./routes";

const { version } = require('../package.json');

const App = () => {
	const auth = useHtxSelector(state => state.auth.auth);
	const dispatch = useHtxDispatch();

	React.useLayoutEffect(() => {
		document.title = version
			? `HTX | BRS Portal - ${version}`
			: `HTX | BRS Portal`
	}, [])

	React.useEffect(() => {
		if (auth) {
			dispatch(getBlackzoneList())
		}
	}, [auth, dispatch])

	const renderRoutes = React.useMemo(() => {
		const renderChildren = (routes: IRoute[]) => {
			return routes.map(item => <Route key={item.path} path={item.path} element={item.component} />)
		}

		return <Routes>
			{ROUTES.map((route) => {
				return <Route
					key={route.path}
					path={route.path}
					element={route.component}>
					{route.children && renderChildren(route.children)}
				</Route>
			})}
			<Route path='/test' element={<ComponentTesting />} />
			<Route path="*" element={<Navigate to='/' replace />} />
		</Routes>
	}, [])

	return <BrowserRouter children={renderRoutes} />
}

export default App;
