import { useCallback, useState } from 'react';

import { generate2FA, verifyOtp } from '@api/authentication';
import { OTPStatus } from '@api/models/authentication';


export const _2FA_TIMER = 180;
export enum ErrorCode {
    WrongCode = 'Entered wrong OTP !!!',
    ExpiredCode = 'OTP expired !!!',
    InactivedCode = 'This OTP is in inactive state !!!',
    CanNotVerified = 'This OTP has already been used !!!',
    BlankCode = 'BLANK_CODE'
}

export interface IOTPInfo {
    account_id: string
    action: string
    created_by: string
    created_date: string
    email_address: string
    expired_datetime: string
    modified_by: string
    modified_date: string
    otp_code: string
    row_id: number
    sent: string
    status: OTPStatus
}


export const useOTPController = () => {
    const [isCalling, setCalling] = useState(false);

    const generate = useCallback(async (reason: string) => {
        const result = { hasError: false, errorMessage: '', otp: {} as IOTPInfo };
        try {  
            const resp = await generate2FA(reason);
            if (resp.status === 200 || resp.status === 201) {
                const respOtp = resp.data as IOTPInfo;
                result.otp = respOtp;
            } else {
                result.hasError = true;
                result.errorMessage = typeof resp?.data === 'string' ? resp?.data : 'Can not generate 2FA code.'
            }
        } catch (err) {
            result.hasError = true;
            result.errorMessage = 'Something wrong!'
        }
        return result;
    }, [])

    const verifyOtpCode = useCallback(async (rowId:number, otpCode: string) => {
        const result = {result: '', hasError:false,}
        try {
            setCalling(true);
            const resp = await verifyOtp(rowId, otpCode);
            if (resp.status === 200 || resp.status === 201) {
                result.result = resp.data;
            } else {
                result.hasError = true
                result.result = resp.data;
            }
            setCalling(false)
        } catch (error) {
            result.result = error as any;
            setCalling(false);
        }
        return result;
    },[])

    return { isCalling, generate, verifyOtpCode }
}
