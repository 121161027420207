import React from 'react';

import { ITheme, useTheme } from '@src/theme';

import Text from '@components/Text';

import { CheckboxGroupContext } from './CheckboxGroup';
import { CheckboxInput, CheckboxSquare, CheckboxWrapper } from './styles';

type CheckboxType = 'round' | 'square';

const StyleCheckbox = {
    round: CheckboxInput,
    square: CheckboxSquare
}

export interface ICheckboxProps extends React.ComponentPropsWithRef<'input'> {
    type?: CheckboxType,
    value?: any;
    name?: string;
    disabled?: boolean;
    margin?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>((props: ICheckboxProps, ref) => {
    const { type = 'round',
        value,
        margin,
        name,
        onChange,
        ...rest } = props;

    const theme: ITheme = useTheme();
    const context = React.useContext(CheckboxGroupContext);

    const Input = StyleCheckbox[type];

    const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (context?.onChange) {
            context.onChange(event, value)
        }
        else {
            onChange && onChange(event)
        }
    }

    return <CheckboxWrapper margin={margin}>
        <Input
            ref={ref}
            type='checkbox'
            theme={theme}
            value={value}
            onChange={onHandleChange}
            {...rest} />
        {name && <Text content={name} color={theme.colors.blue.dark} />}
    </CheckboxWrapper>
});

export default Checkbox;
