import { IToken } from '@models/jwt.model';
import StorageService from '@services/StorageService';
import { STORAGE_KEY_AUTHENTICATION } from '@static/authentication';
import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

const baseConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL || '/api'
};

const protocol = axios.create(baseConfig);

protocol.interceptors.request.use(function (config) {
    const jwt = StorageService.getStorage<IToken>(STORAGE_KEY_AUTHENTICATION)
    const headers: any = {
        'Content-Type': 'application/json',
    };

    if (jwt?.token) {
        headers['Authorization'] = `Bearer ${jwt?.token}`
    }

    config.headers = {
        ...config.headers,
        ...headers,
    };
    return config;
});

protocol.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const {status, headers} = error.response
        if (status === 401) {
            if (headers["is-token-expired"]) {
                return resetTokenAndReattemptRequest(error)
            } else {
                window.location.href = '/logout'
            }
        }
        return get(error, 'response', {})
    }
)

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers : any[] = [];

async function resetTokenAndReattemptRequest(error: any) {
  try {
    const { response: errorResponse } = error;
    const jwt = StorageService.getStorage<IToken>(STORAGE_KEY_AUTHENTICATION)

    if (!jwt?.refreshToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
    /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber((jwt: IToken) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + jwt.token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await refreshToken();
      if (response.status !== 200) {
        window.location.href = '/logout'
        return Promise.reject(error);
      }
      const newToken = response.data as IToken;

      StorageService.setStorage(STORAGE_KEY_AUTHENTICATION, newToken)

      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token: any) {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

export function refreshToken() {
    const jwt = StorageService.getStorage<IToken>(STORAGE_KEY_AUTHENTICATION)
    return protocol.post('/identity/refresh', jwt)
}

export { protocol }
