import { styled } from '@linaria/react';
import { ITheme } from '@src/theme';

interface IStyleProps {
    theme?: ITheme,
    margin?: string,
}

export const Container = styled.div<{ margin?: string }>`
    margin: ${props => props.margin || ''}px;
    display: flex;
    flex-direction: column;
`;

export const CheckboxWrapper = styled.div<{ margin?: string }>`
    display: flex;
    align-items: center;
    margin: ${props => props.margin || ''};

    p {
        margin-left: 7px;
    }
`;

export const CheckboxGroupWrapper = styled.div<{ maxColumns?: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.maxColumns || 4}, auto);
`;

export const CheckboxInput = styled.input<IStyleProps>`
    cursor: pointer;
    box-sizing: border-box;
    flex: 0 0 15px;
    height: 15px;
    width: 15px;
    margin: 0;
    border-radius: 50%;
    border: 1px solid ${props => props.disabled ? props?.theme?.colors.grey.default : props.theme?.colors.blue.default};
    appearance: none;
    -webkit-appearance: none;
    outline: none;

    &:checked {
        background-image: url("@assets/icons/ds-htx-checkbox-checked-icon.png");
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: center;
    }
`

export const CheckboxSquare = styled(CheckboxInput)`
    border-radius: 0;
    width: 19px;
    height: 19px;
    flex: 0 0 19px;
`